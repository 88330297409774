import React from 'react'
import { Button, Typography } from '@material-ui/core'
import { FilterList } from '@material-ui/icons'
import { Spinner } from '@mindlab-vojo/component-library'

interface Props {
  currentApplications: number
  isButtonDisabled: boolean
  isLoading: boolean
  onClickContinue()
  onClickFilter()
  onCleanFilter(event: React.MouseEvent<HTMLButtonElement>)
  totalApplications: number
  totalFilters: number
}

function FilterCounter ({
  currentApplications,
  isButtonDisabled,
  isLoading,
  onClickContinue,
  onClickFilter,
  onCleanFilter,
  totalApplications,
  totalFilters
}: Props) {
  return (
    <div
      className="FilterCounter"
      id={'filter-counter'}>
      <div className="FilterCounter__Content">
        <div className="FilterCounter__Block FilterCounter__Title">
          <Typography>
            {'Resumo'}
          </Typography>
        </div>
        <div className="FilterCounter__Block FilterCounter__CurrentApplications">
          {
            isLoading
              ? (
                <Spinner
                  height={'30px'}
                  width={'30px'}
                  spinnerArea={'50px'}
                />
              )
              : (
                <Typography>
                  {`${currentApplications}`}
                </Typography>
              )
          }
        </div>
        <div className="FilterCounter__Block FilterCounter__TotalApplications">
          <Typography>
            {`de ${totalApplications}`}
          </Typography>
        </div>
        <div className="FilterCounter__Block--spaced  FilterCounter__TotalFilters">
          <FilterList
            className="FilterCounter__TotalFilters__Icon" />
          <Typography>
            {`${totalFilters} filtros aplicados`}
          </Typography>
        </div>
        <div className={`FilterCounter__Block FilterCounter__Button${isButtonDisabled ? '--disabled' : ''}`}>
          <Button
            disabled={isButtonDisabled}
            onClick={() => isButtonDisabled ? null : onClickContinue()}
            size="small"
            color="primary">
            {'CONTINUAR'}
          </Button>
        </div>
        <div className={`FilterCounter__Block FilterCounter__Button${isButtonDisabled ? '--disabled' : ''}`}>
          <Button
            disabled={isButtonDisabled}
            onClick={() => isButtonDisabled ? null : onClickFilter()}
            size="small"
            color="primary">
            {'Aplicar Filtro'}
          </Button>
        </div>
        <div className={'FilterCounter__Block FilterCounter__Button'}>
          <Button
            onClick={(event) => onCleanFilter(event)}
            size="small"
            color="primary">
            {'Limpar Filtro'}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default FilterCounter
