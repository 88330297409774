import React, { Component } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import * as LoginActions from '../../redux/login/actions'
import { LoginState, LoginI } from '../../redux/login/types'
import { ApplicationState } from '../../redux/store'

import { defaultInputState } from '../../utils/forms/formDefaultStates'
import { Input } from '../../utils/forms/input.interface'
import { FormInput, StickyToast } from '@mindlab-vojo/component-library'
import { Button, Typography } from '@material-ui/core'
import Logo from '../../utils/style/logo.svg'

interface StateProps {
    login: LoginState
}

interface DispatchProps {
    loginSet(data: LoginI): void
}

interface Fields {
    username: Input
    password: Input
}

interface State {
    fields: Fields,
    isFieldsValid: boolean,
    showError: boolean
  }

class Login extends Component<any, State> {
  constructor (props) {
    super(props)
    this.state = {
      fields: {
        username: { ...defaultInputState },
        password: { ...defaultInputState }
      },
      isFieldsValid: false,
      showError: false
    }
  }

     handleFieldUpdate = async (fieldName, state) => {
       await this.setState({
         fields: {
           ...this.state.fields,
           [fieldName]: {
             showError: false,
             ...state
           }
         }
       })
     }

    createRequestObject = () => {
      const fieldKeys = Object.keys(this.state.fields)
      const data: LoginI = { admin: true }
      for (let i = 0; i < fieldKeys.length; i++) {
        const field = fieldKeys[i]
        data[field] = this.state.fields[field].value
      }
      return data
    }

     checkIsFieldsValid = async (fieldKeys) => {
       for (let i = 0; i < fieldKeys.length; i++) {
         const field = fieldKeys[i]
         if (this.state.fields[field].isValid === false) {
           await this.setState({ isFieldsValid: false })
           return
         }
         if (this.state.fields[field].value === '') {
           await this.setState({ isFieldsValid: false })
           return
         }
       }
       await this.setState({ isFieldsValid: true })
     }

     handleShowErrorOnFields = async (fieldKeys) => {
       for (let i = 0; i < fieldKeys.length; i++) {
         const field = fieldKeys[i]
         await this.setState({
           fields: {
             ...this.state.fields,
             [field]: {
               ...this.state.fields[field],
               showError: true
             }
           }
         })
       }
     }

     handleLoginClick = async () => {
       const fieldKeys = Object.keys(this.state.fields)

       await this.checkIsFieldsValid(fieldKeys)
       await this.handleShowErrorOnFields(fieldKeys)

       if (this.state.isFieldsValid) {
         const requestData = this.createRequestObject()
         await this.props.loginSet(requestData)
       }
     }

     render () {
       return (
         <div className="View">
           { this.props.login.error && (
             <div style={{
               display: 'block',
               width: '100%'
             }}>
               <StickyToast show>
                 <Typography style={{ color: '#ff4d4d' }}>
                   {this.props.login.error.message}
                 </Typography>
               </StickyToast>
             </div>
           )}
           <div className="Login">
             <div className="Login__Content">
               <img src={Logo} alt="Logo Vojo" className="Login__Content__Logo"/>
               <div className="Login__Content__Fields">
                 <FormInput
                   id="username"
                   isRequired
                   label="E-mail"
                   name="username"
                   onInputBlur={(state) => this.handleFieldUpdate('username', state)}
                   onInputChange={(state) => { this.handleFieldUpdate('username', state) } }
                   placeholder="E-mail"
                   type="text"
                   value={this.state.fields.username.value}
                 />
                 <FormInput
                   id="password"
                   isRequired
                   label="Senha"
                   name="password"
                   onInputBlur={async (state) => await this.handleFieldUpdate('password', state)}
                   onInputChange={async (state) => await this.handleFieldUpdate('password', state)}
                   placeholder="Senha"
                   type="text"
                   isPassword
                   value={this.state.fields.password.value}
                 />
               </div>
               <Button
                 color="primary"
                 variant='contained'
                 style={{ width: '100%' }}
                 onClick={async () => await this.handleLoginClick()}>
                        Entrar
               </Button>
             </div>
           </div>
         </div>
       )
     }
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
  login: state.login
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(
  LoginActions, dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(Login)
