/* eslint-disable react/jsx-key */

import React, { Component } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators, Dispatch } from 'redux'
import { Spinner, StickyToast, ModalActions, ModalInformation } from '@mindlab-vojo/component-library'
import { Typography, Button } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined'
import * as routes from '../../routes/routes'
import { CohortState, CohortI, CountCohort } from '../../redux/cohorts/types'
import { AuthorizationState } from '../../redux/authorization/types'
import { CompaniesState, RequestCohortI } from '../../redux/companies/types'
import { ApplicationState } from '../../redux/store'
import * as CohortsActions from '../../redux/cohorts/actions'
import * as AuthorizationActions from '../../redux/authorization/actions'
import * as CompaniesActions from '../../redux/companies/actions'
import CohortCard from '../../components/CohortCard'
import HeaderHome from '../../components/HeaderHome'
import StatusSelector from '../../components/StatusSelector'
import { ECohortStatus } from '../../utils/enums/cohort-status.enum'
import getUserRole from '../../utils/UserData/getUserRole'
import { countCohorts } from '../../redux/cohorts/services'
import { GetConfiguration } from '../../services/Configurations/configurations.service.interface'
import { getConfiguration } from '../../services/Configurations/configurations.service'
import { ViewElement } from '../../components/DynamicPage/ViewElement'
import { ComponentError } from '../../utils/interfaces/component-error.interface'
import { UserRole } from '../../utils/enums/user-role.enum'

interface StateProps {
  cohorts: CohortState
  authorization: AuthorizationState
  companies: CompaniesState
}

interface DispatchProps {
  cohortSet(data): void
  cohortPreviewClear(): void
  cohortSaveClear(): void
  authorizationUnset(): void
  requestCohort(payload: RequestCohortI): void
}

interface OwnProps extends RouteComponentProps {
  defaultError: {
    message: string
  },
  getConfiguration: GetConfiguration,
  pageCode: string
}

type Props = StateProps & DispatchProps & OwnProps

interface State {
  cohort?: any
  selectedCohortStatus: ECohortStatus[]
  userRole: UserRole | null,
  showRequestModal: boolean,
  showRequestModalResponse: boolean,
  requestMessage: string,
  validMessage: boolean,
  count: CountCohort,
  components: ViewElement[],
  error: ComponentError
}
class CohortList extends Component<Props, State> {
  static defaultProps = {
    defaultError: {
      message: 'Ops, um erro inesperado ocorreu.'
    },
    pageCode: 'page_cohort_admin'
  }

  /**
   * Returns list containing definition of cohort status tabs
   * @param role role of authenticated user
   * @returns list with objects representing each tab
   */

  getSelectionOptions = (role: UserRole) => [
    this.getPendingTab(role),
    this.getApprovedTab(role),
    this.getDeniedTab(role)

  ]
  /**
   * Returns pending cohorts tab information
   * @param role role of authenticated user
   * @returns object representing the pending cohorts tab
   */

  getPendingTab = (role: UserRole) => {
    const count = role === UserRole.MANAGER
      ? (this.state?.count?.WAITING)
      : (this.state?.count?.WAITING + this.state?.count?.PENDING + this.state?.count?.VALIDATING)

    const status = role === UserRole.MANAGER
      ? [ECohortStatus.WAITING]
      : [ECohortStatus.PENDING, ECohortStatus.WAITING, ECohortStatus.VALIDATING]

    return { title: 'Pendentes', count, status }
  }

  getApprovedTab = (role: UserRole) => {
    const count = this.state?.count?.ACCEPTED + this.state?.count?.ACTIVATED
    const status = [ECohortStatus.ACCEPTED, ECohortStatus.ACTIVATED]

    return { title: 'Aprovados', count, status }
  }

  getDeniedTab = (role: UserRole) => {
    const count = this.state?.count?.DENIED
    const status = [ECohortStatus.DENIED]

    return { title: 'Negados', count, status }
  }

  state = {
    userRole: getUserRole(),
    selectedCohortStatus: this.getSelectionOptions(getUserRole())[0]?.status,
    showRequestModal: false,
    showRequestModalResponse: false,
    requestMessage: '',
    validMessage: true,
    count: undefined,
    components: [],
    error: {
      code: '',
      value: false,
      message: ''
    }
  }

  componentDidMount() {
    this.props.cohortPreviewClear()
    this.props.cohortSaveClear()
    this.setCohortCount()
    this.props.cohortSet({ status: this.state.selectedCohortStatus })
    this.props.authorization?.data?.companyId && this.setDynamicComponents()
  }

  /**
   * Recupera os componentes dinâmicos desse componente, que são retornados do back-end
   */
  async setDynamicComponents() {
    try {
      const configuration = await getConfiguration(this.props.authorization?.data?.companyId)
      const page = configuration?.data[getUserRole().toLowerCase()]?.pages.find(page => page.code === this.props.pageCode)
      await this.setState({ components: page?.components || [] })
    } catch (e) {
      e.statusCode !== 404 && this.handleError({
        value: true,
        message: 'Um erro inesperado ocorreu! (setDynamicComponents)'
      })
    }
  }

  /**
   * Atualiza o estado de erro desse componente
   * @param error
   */
  handleError(error: ComponentError): void {
    this.setState({ error })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.selectedCohortStatus !== this.state.selectedCohortStatus) {
      this.props.cohortPreviewClear()
      this.props.cohortSaveClear()
      this.props.cohortSet({ status: this.state.selectedCohortStatus })
    }
    if ((prevProps.companies !== this.props.companies) && (this.props.companies.requestCohort.success || this.props.companies.requestCohort.error)) {
      this.setState({ showRequestModal: false })
      this.setState({ showRequestModalResponse: true })
    }
  }

  setCohortCount = async () => {
    try {
      const count = await countCohorts([
        ECohortStatus.ACCEPTED,
        ECohortStatus.ACTIVATED,
        ECohortStatus.DENIED,
        ECohortStatus.PENDING,
        ECohortStatus.WAITING,
        ECohortStatus.VALIDATING
      ])
      this.setState({ count: count })
    } catch (err) {
      this.setState({ error: { ...this.state.error, value: true } })
    }
  }

  handleCohortStatusSelection = (cohortStatus: ECohortStatus[]): void => {
    this.props.cohortSet({ status: this.state.selectedCohortStatus })
    this.setState({ selectedCohortStatus: cohortStatus })
  }

  renderCohortsCards = () => {
    let cohortsList: CohortI[] = this.props.cohorts.data!
    cohortsList = cohortsList.filter(cohort =>
      this.state.selectedCohortStatus.includes(cohort.status[0].name)
    )
    return cohortsList.map(
      cohort => {
        return (
          <CohortCard
            key={cohort.id}
            cohort={cohort}
            userRole={this.state.userRole}
            selectedCohortStatus={this.state.selectedCohortStatus}
          />
        )
      }
    )
  }

  renderViewContent = () => {
    return (
      <>
        {this.props.cohorts.data ? this.renderCohortsCards() : <Spinner spinnerArea="30hv" width="100%" />}
      </>
    )
  }

  /**
  * @description Informa se o valor do conteúdo do textArea é valido e altera o estado validMessage desse componente
  * @returns **true** se a mensagem for válida, **false** do contrário
  */

  isValidMessage = () => {
    const message: string = this.state.requestMessage
    const isValid: boolean = Boolean(message)
    this.setState({ validMessage: isValid })
    return isValid
  }

  handleChangeMessage = async (value: string) => {
    await this.setState({ requestMessage: value })
    this.isValidMessage()
  }

  requestCohort = () => {
    const companyId: string | undefined = this.props.authorization.data?.companyId
    const messageIsValid: boolean = this.isValidMessage()
    if (companyId && messageIsValid) {
      this.props.requestCohort({ message: this.state.requestMessage, companyId: companyId })
    }
  }

  renderRequestCohort = () => {
    return (
      <div className="ModalRequestCohort" >
        <ModalActions
          modalWidth={'390px'}
          title={'Solicitar Candidatos'}
          description={'Digite a quantidade e os critérios dos candidatos que gostaria de solicitar ao Vojo!'}
          actions={
            this.props.companies.requestCohort.isLoading ? (<Spinner />) : (<div className="ModalRequestCohort__Buttons">
              <Button
                key={1}
                style={{ color: '#B8B5C6' }}
                color="default"
                onClick={() => this.setState({ showRequestModal: false })}>
                {'CANCELAR'}
              </Button>
              <Button
                key={2}
                style={{ color: '#391DDD' }}
                color="default"
                onClick={() => this.requestCohort()}>
                {'ENVIAR'}
              </Button>
            </div>)
          }>
          <textarea
            placeholder={'Exemplo:\n80 candidatos\nSão Paulo (SP)\nDisponibilidade: Segunda a Sexta\nModais: Carro de passeio e Moto'}
            className={this.state.validMessage ? 'TextArea' : 'TextArea TextArea__Invalid'}
            onChange={(e) => this.handleChangeMessage(e.target.value)} />
        </ModalActions>
      </div>
    )
  }

  renderRequestCohortResponse = () => {
    return (
      <div className="ModalRequestCohort" >
        <ModalInformation
          modalWidth={'280px'}
          title={this.props.companies.requestCohort.success ? 'Solicitação enviada!' : 'A mensagem falhou!'}
          icon={
            <div className={'ModalRequestCohort__Icon'}>
              <div className={`ModalRequestCohort__Icon__${this.props.companies.requestCohort.success ? 'Success' : 'Error'}`}>
                {this.props.companies.requestCohort.success
                  ? <CheckCircleOutlineIcon style={{ fontSize: 100 }} />
                  : <CancelOutlinedIcon style={{ fontSize: 100 }} />}
              </div>
            </div>
          }
          description={this.props.companies.requestCohort.success ? 'A sua mensagem foi enviada com sucesso!' : 'A sua solicitação não foi enviada, tente novamente!'}
          actions={
            <div className="ModalRequestCohort__Button">
              <Button
                style={{ width: '100%' }}
                color="primary"
                variant='contained'
                onClick={() => this.setState({ showRequestModalResponse: false, requestMessage: '' })}>
                {'OK!'}
              </Button>
            </div>
          }>
        </ModalInformation>
      </div>
    )
  }

  render() {
    return (
      <div className="View">
        {this.props.cohorts.error && (
          <div style={{
            display: 'block',
            width: '100%'
          }}>
            <StickyToast show>
              <Typography style={{ color: '#ff4d4d' }}>
                {this.props.cohorts.error.message}
              </Typography>
            </StickyToast>
          </div>
        )}
        {this.state.error.value && (
          <div style={{
            display: 'block',
            width: '100%'
          }}>
            <StickyToast show>
              <Typography style={{ color: '#ff4d4d' }}>
                {this.state.error.message || this.props.defaultError.message}
              </Typography>
            </StickyToast>
          </div>
        )}
        <HeaderHome
          cohortCount={this.props.cohorts.data?.length}
          onClickButton={() => this.state.userRole === 'OPERATOR' ? this.props.history.push(routes.FUNCTION_LIST) : this.setState({ showRequestModal: true })}
          onClickLogout={() => this.props.authorizationUnset()}
          operator={this.state.userRole === 'OPERATOR'}
          buttons={this.state.components}
          title={this.props.authorization.data?.companySocialName ? this.props.authorization.data?.companySocialName : 'COHORTS'} />
        {this.state.showRequestModal && this.renderRequestCohort()}
        {this.state.showRequestModalResponse && this.renderRequestCohortResponse()}
        <div className="View__Background View__Background--header-larger">
          <div className="View__Content">
            <StatusSelector
              onClickOption={(option) => this.handleCohortStatusSelection(option.status)}
              optionList={this.getSelectionOptions(getUserRole())}
            />
            <div className="CohortList">
              {this.renderViewContent()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state: ApplicationState): StateProps => ({
  cohorts: state.cohorts,
  authorization: state.authorization,
  companies: state.companies
})

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => bindActionCreators(
  { ...CohortsActions, ...AuthorizationActions, ...CompaniesActions }, dispatch
)

export default connect(mapStateToProps, mapDispatchToProps)(CohortList)
